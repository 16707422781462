import Settled from "./Settled";
import Loading from "./Loading";
import Item from "@components/Reason/CareDiscoverySearch/ResultList/Item";

const ResultList = Object.assign({}, Settled, {
  Loading,
  Item,
});

export default ResultList;
