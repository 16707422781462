import CareEntryListRow, {CareEntryRowVariant} from "@components/CareEntryRow";
import React, {useMemo} from "react";

import {useHoveredIndexController} from "../../../../../hooks/useHoveredIndexController";
import {getBorder} from "./border";
import {ReasonForCareEntryIcon} from "../../../../../hooks/useCareEntryExtensions";
import {LocationForSoonestSlotForSlug} from "../../../../../queries/useSoonestSlotByReasonSlug";

interface Props {
  keyPrefix: string;
  reasons: ReasonForCareEntryIcon[];
  rowVariant?: CareEntryRowVariant;
  hideBorders?: boolean;
  loadingRowCount?: number;
}

const getRowKey = (prefix: string, slug: string) => `${prefix}-${slug}`;
const blankResult = {name: "", slug: ""};
const emptyLocations: LocationForSoonestSlotForSlug[] = [];

const ResultList: React.FC<Props> = ({
  keyPrefix,
  reasons,
  rowVariant,
  hideBorders = false,
  loadingRowCount = 0,
}) => {
  const {activeIndex, setHovered} = useHoveredIndexController();
  const rows = useMemo(
    () =>
      Array(loadingRowCount)
        .fill(null)
        .map((_, i, arr) => (
          // Normally do not ignore these, but this is only to set a hover style and has no a11y implication
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <li
            key={getRowKey(keyPrefix, i.toString())}
            className={`list-none ${getBorder(i, activeIndex, arr.length, hideBorders)}`}
            onMouseEnter={() => setHovered(i)}
          >
            <CareEntryListRow
              reasons={reasons}
              locations={emptyLocations}
              rank={i + 1}
              cypressKey={`${keyPrefix}-${i}-loading`}
              result={blankResult}
              variant={rowVariant}
              isLoading
            />
          </li>
        )),
    [activeIndex, hideBorders, keyPrefix, loadingRowCount, reasons, rowVariant, setHovered],
  );
  return <div className="flex flex-col transition">{rows}</div>;
};

export default React.memo(ResultList);
