const border = "after:content-[''] after:h-[1px] after:w-[100%] after:flex after:transition-colors";

const borderStyleSwitch = (disabled: boolean) =>
  disabled ? `${border} after:bg-transparent` : `${border} after:bg-gray100`;

export const getBorder = (
  index: number,
  hoveredIndex: number | null,
  totalItemCount: number,
  disable: boolean,
) =>
  borderStyleSwitch(
    disable ||
      index === hoveredIndex ||
      index === (hoveredIndex || -100) - 1 ||
      index === totalItemCount - 1,
  );
