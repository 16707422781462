import {PracticeApptReason} from "@services/types";
import {RootStateApptReason} from "../store/types";
import {isReasonDiscoverable} from "./isReasonDiscoverable";

const pickApptReason = (reason: PracticeApptReason): RootStateApptReason => ({
  id: reason.id,
  slug: reason.slug,
  name: reason.name,
  iconName: reason.iconName,
  description: reason.description,
  specialtyIds: reason.specialtyIds,
  newPatientDuration: reason.newPatientDuration,
  supportsVideoVisit: reason.supportsVideoVisit,
  supportsInstantVisit: reason.supportsInstantVisit,
  practiceApptReasonSpecialties: reason.practiceApptReasonSpecialties,
  code: reason.code,
});

export default function filterAppointmentReasons(
  reasons: PracticeApptReason[],
): RootStateApptReason[] {
  return reasons.filter(reason => isReasonDiscoverable(reason)).map(pickApptReason);
}
