import Collapse from "../../../../animations/Collapse";
import React, {PropsWithChildren} from "react";

type Props = {
  className: string;
  disableAnimation?: boolean;
  firstLocRef?: React.RefObject<HTMLLIElement>;
  hide?: boolean;
  onMouseEnter: () => unknown;
  transitionSpeed: number;
};
const Item: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  disableAnimation,
  firstLocRef,
  hide,
  onMouseEnter,
  transitionSpeed,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li ref={firstLocRef} onMouseEnter={onMouseEnter} className={`list-none ${className}`}>
      <Collapse
        in={!hide}
        appear
        disableAnimation={disableAnimation}
        duration={transitionSpeed}
        unmountOnExit
      >
        {children}
      </Collapse>
    </li>
  );
};

export default React.memo(Item);
