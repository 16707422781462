import {loadAppointmentReasons} from "@services/monolith/appointmentReasons";
import {useEffect, useState} from "react";

import {RootState, actions, useTypedDispatch, useTypedSelector} from "../store";
import {RootStateApptReason} from "../store/types";
import tee from "../utils/promise/tee";
import filterAppointmentReasons from "../utils/filterAppointmentReasons";

const selectApptReasons = (state: RootState): RootStateApptReason[] | undefined =>
  state.config.reasons;

export const useLoadApptReasons = (): RootStateApptReason[] => {
  const [state, setState] = useState<RootStateApptReason[]>([]);
  const dispatch = useTypedDispatch();
  const maybeAlreadyLoadedReasons = useTypedSelector(selectApptReasons);
  const reasonsAreAlreadyLoaded =
    Array.isArray(maybeAlreadyLoadedReasons) && maybeAlreadyLoadedReasons.length > 0;

  useEffect(() => {
    if (!reasonsAreAlreadyLoaded) {
      loadAppointmentReasons()
        .then(filterAppointmentReasons)
        .then(tee(reasons => dispatch(actions.setConfig({reasons}))))
        .then(setState);
    }
  }, [dispatch, reasonsAreAlreadyLoaded]);

  return state;
};
