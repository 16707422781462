import {debounce} from "lodash";
import {useMemo, useState} from "react";

import {ApptReasonId} from "../../../../constants/apptReasons";
import {useTranslation} from "../../../../i18n";
import {useSearchCareEntriesQuery} from "../../../../queries/useSearchCareEntries";
import {getSchedulingRedirectUrl} from "../../../../utils/getRedirectUrl";
import {ExtendedCareEntrySearchResult} from "../../CareEntryRow";
import {noSymptomKeywords} from "../SpecialSearchKeywords";
import {ApptReasonForCareEntryResultOptions, buildSearchReporter} from "./careDiscoveryAnalytics";

const queryLengthThreshold = 2;

export const useCareDiscoverySearchController = (
  defaultResults: ExtendedCareEntrySearchResult[],
  reasons: ApptReasonForCareEntryResultOptions[],
) => {
  const {t} = useTranslation();
  const [query, setQuery] = useState("");
  const isShortQuery = query.length < queryLengthThreshold;
  const isNoSymptomsSearched = noSymptomKeywords.includes(query?.trim().toLowerCase());
  const handleQueryChange = useMemo(
    () =>
      debounce(val => {
        setQuery(val);
      }, 400),
    [],
  );

  const noResultSuggestions: ExtendedCareEntrySearchResult[] = useMemo(
    () => [
      {
        name: t("General urgent care visit"),
        description: "",
        appointment_reason: ApptReasonId.GENERAL_URGENT_CARE,
        slug: "general-urgent-care-visit",
        href: getSchedulingRedirectUrl({
          apptReasonId: ApptReasonId.GENERAL_URGENT_CARE,
        }),
        icon: "book-appointment",
      },
    ],
    [t],
  );

  const queryAnalyticsReporter = useMemo(
    () => buildSearchReporter(query, reasons, noResultSuggestions),
    [noResultSuggestions, query, reasons],
  );
  const searchCareEntriesQuery = useSearchCareEntriesQuery(query, {
    skip: isShortQuery,
    isNoSymptomsSearched,
    queryAnalyticsReporter,
  });

  const emptyData = searchCareEntriesQuery.data.length === 0;
  const showDefaultResults = isShortQuery;
  const showNoResults = emptyData && !showDefaultResults && searchCareEntriesQuery.isSettled;

  const resultsToRender = showNoResults
    ? noResultSuggestions
    : showDefaultResults
    ? defaultResults
    : searchCareEntriesQuery.data;

  return {
    query,
    setQuery,
    isShortQuery,
    isNoSymptomsSearched,
    handleQueryChange,
    searchCareEntriesQuery,
    showNoResults,
    showDefaultResults,
    emptyData,
    resultsToRender,
  };
};
