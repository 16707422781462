import React, {MouseEventHandler, PropsWithChildren} from "react";

import {PhoneNumber} from "../../../CareDiscovery/PhoneNumber";

interface Props {
  className?: string;
  phoneNumber?: string | null;
  onMouseLeave: MouseEventHandler<HTMLUListElement>;
}

const Settled: React.FC<PropsWithChildren<Props>> = ({
  children,
  className = "",
  onMouseLeave,
  phoneNumber,
}) => {
  return (
    // Normally do not ignore these, but this is only to set a hover style and has no a11y implication
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <ul className={`flex flex-col ${className}`} onMouseLeave={onMouseLeave}>
      {children}
      {phoneNumber && <PhoneNumber phoneNumber={phoneNumber} />}
    </ul>
  );
};

export default React.memo(Settled);
