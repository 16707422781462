import memoizee from "memoizee";

import {MsMap} from "../../constants/MsMap";
import {PracticeId} from "../../constants/practiceIds";
import monolith from "../../utils/fetch/fetches";
import {PracticeApptReason} from "@services/types";

export function loadAppointmentReasons(
  practiceId: string = PracticeId.CARBON,
  video?: boolean,
  instant?: boolean,
) {
  return memoizedLoadApptReasonsExportedForTesting(practiceId, video, instant).catch(() => []);
}

// Exported only for testing. Prod code should call loadAppointmentReasons().
export const memoizedLoadApptReasonsExportedForTesting = memoizee(
  async (
    practiceId: string = PracticeId.CARBON,
    video?,
    instant?,
  ): Promise<PracticeApptReason[]> => {
    const response = await monolith()(
      `/hib/practices/${practiceId}/apptReasons/v2?${video ? "supportsVideoVisit=true" : ""}${
        instant ? "supportsInstantVisit=true" : ""
      }`,
    );
    return await response.json();
  },
  {
    maxAge: MsMap.ONE_MINUTE * 20,
    promise: true, // prevents caching bad response (exceptions)
  },
);
