import {Translate} from "next-translate";
import {useRouter} from "next/router";
import {useCallback, useMemo, useState} from "react";

import {CareSlug} from "../../../../constants/cares";
import {useTranslation} from "../../../../i18n";
import {v5Pages} from "../../../_common/_constants";
import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from "../../CareEntryRow";
import useNextPagesPaths from "../../../../hooks/app-pages-transition/useNextPagesPaths";

const initialState = {
  stack: [] as ExtendedCareEntrySearchResult[],
  direction: "add" as "pop" | "add",
};

const MAX_STACK_SIZE = 4;

/**
 * When feature is enabled, these topics should always expand
 */
export const alwaysExpand: string[] = [
  CareSlug.COVID_CARE,
  CareSlug.COVID_TRAVEL_TESTING,
  CareSlug.COVID_TESTING,
];

/**
 * This can be moved to a better place after experiment conclusion.
 * For now it makes sense to couple it with the stack because it relies on its output.
 */
const getTitleAndDescriptionOverride = (t: Translate, slug?: string) => ({
  title:
    slug === CareSlug.COVID_TRAVEL_TESTING
      ? t("Which travel test do you need?")
      : slug === CareSlug.COVID_TESTING
      ? t("Which COVID test do you need?")
      : undefined,
  desciption:
    slug === CareSlug.COVID_TRAVEL_TESTING
      ? t(
          "Patients are responsible for verifying which test meets the travel requirements for their destination. Travel testing is recommended for asymptomatic patients, and is not covered by insurance.",
        )
      : undefined,
  variant:
    slug === CareSlug.COVID_TRAVEL_TESTING || slug === CareSlug.COVID_TESTING
      ? CareEntryRowVariant.MD
      : CareEntryRowVariant.SM,
});

export const areRecursiveTopicsEnabled = (pathname: string) =>
  pathname === v5Pages.home || pathname === v5Pages.clinicDetails;

export const useAreRecursiveTopicsEnabled = () => {
  const {pathname} = useRouter();
  return areRecursiveTopicsEnabled(pathname);
};

const shouldExpandTopic = (
  slug: string,
  topicStackLength: number,
  hasRelatedCares: boolean,
  pathname: string,
) => {
  if (!hasRelatedCares) {
    return false;
  }

  switch (pathname) {
    case v5Pages.booking:
      return true;
    case v5Pages.clinicDetails:
      return Boolean(topicStackLength < MAX_STACK_SIZE || topicStackLength < 1);
    case v5Pages.home:
      return Boolean(alwaysExpand.includes(slug));
    default:
      return false;
  }
};

export const useTopicStack = () => {
  const {t} = useTranslation();
  const {pathname} = useNextPagesPaths();
  const [{stack, direction}, setState] = useState(initialState);
  const topOfStack = stack[stack.length - 1];

  const reset = useCallback(() => setState(initialState), [setState]);
  const add = useCallback((care: ExtendedCareEntrySearchResult) => {
    setState(state => ({
      stack: [...state.stack, care],
      direction: "add",
    }));
  }, []);

  const maybeAddToStack = useCallback(
    (slug: string, hasRelatedCares: boolean): typeof add | undefined => {
      if (shouldExpandTopic(slug, stack.length, hasRelatedCares, pathname)) {
        return add;
      }
    },
    [add, pathname, stack.length],
  );

  const pop = useCallback(() => {
    setState(state => ({
      stack: state.stack.length === 0 ? state.stack : state.stack.slice(0, state.stack.length - 1),
      direction: "pop",
    }));
  }, []);

  return useMemo(
    () => ({
      ...(areRecursiveTopicsEnabled(pathname) &&
        getTitleAndDescriptionOverride(t, topOfStack?.slug)),
      stack,
      direction,
      isEmpty: stack.length === 0,
      reset,
      maybeAddToStack,
      pop,
    }),
    [pathname, t, topOfStack?.slug, stack.length, direction, reset, maybeAddToStack, pop],
  );
};
